<template>
  <div class="inner-layout">
    <div class="searchP-title">
      <span class="color-1">{{ input }}</span>에 대한 검색 결과입니다.
    </div>

    <div class="sort-box">
      <div v-for="(item, index) in opt.sort" :key="`sort${index}`" :class="{'on' : item.value === sort}" @click="setSort(item.value)">{{ item.text }}</div>
    </div>

    <div class="searchP-heading-group">
      <div class="heading-3">{{ typeName }}</div>
    </div>

    <div v-if="isGgomunity" class="community-list">
      <small-card v-for="item in list" :key="`search-more-${item.boIdx}`" :item="item" cardType="default" @click.native="goDetail(item.boIdx)"/>
    </div>
    <div v-else class="item-container column-4">
      <card v-for="item in list" :key="`search-more-${item.boIdx}`" :item="item" cardType="default" @click="goDetail(item.boIdx)"/>
    </div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import listMixin from "@/mixins/listMixin"
import SmallCard from "@/components/common/SmallCard"
export default {
  mixins: [listMixin],
  components: {SmallCard},
  data() {
    return {
      type: this.$route.params.type,
      typeName: null,
      cateCode: null,
      input: this.$route.query.input,
    }
  },
  computed: {
    isGgomunity() {
      return this.type === 'ggomunity'
    }
  },
  created() {
    if (this.type === 'tip') {
      this.typeName = '꼬리TIP'
      this.cateCode = 10
    }
    if (this.type === 'lab') {
      this.typeName = '연구일지'
      this.cateCode = 20
    }
    if (this.type === 'ggomunity') {
      this.typeName = '꼬뮤니티'
      this.cateCode = 80
    }
    if (!(this.type && this.input)) this.$router.push('/')
  },
  methods: {
    async infiniteHandler($state) {
      try {
        const { result, list } = await this.$api.getSearchMoreList({
          cateCode: this.cateCode,
          search: this.input,
          orderBy: this.sort,
          page: this.page,
          pageSize: this.pageSize,
        })
        if (result === 'success') {
          if (list.length > 0) {
            this.list = this.page == 1 ? list : this.list.concat(list)
            setTimeout(() => {
              this.page += 1;
              $state.loaded();
            }, 1000);
          }
          else {
            $state.complete();
          }
        }
        else {
          $state.complete();
        }
      } catch (error) {
        console.log(error);
      }
    },
    goDetail(id) {
      this.setBeforeContent()
      this.$router.push(`/${this.type}/${id}`)
    },
  }
}
</script>
