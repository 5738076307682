<template>
    <div class="community-item" >
    <div class="item-img" v-if="item.thumbnail">
      <div class="thumb-img"><image-alt :src="item.thumbnail" altType="list"/></div>
    </div>
    <div class="item-con">
      <span class="color-1">{{ getCategoryName }}</span>
      <div class="item-title">{{ item.title }}</div>
      <div class="info"><span>댓글 {{ item.commentCount }} · 추천 {{ item.likeCnt }} · </span>조회 {{ item.view ? Number(item.view).numberFormat() : 0 }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    isSearch: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    getCategoryName() {
      if (this.isSearch) {
        return `[${this.item.parentCateName}/${this.item.categoryName}]`
      }
      return `[${this.item.categoryName}]`
    }
  },
  methods: {
  }
}
</script>
